import React from "react";
import "../css/Services.css"

const Services = () => {
  return (
    <div className="services-container">
      <h1>Our Services</h1>
      <ul>
        <li>Tally GST Registration</li>
        <li>ITR Filing</li>
        
        <li>Tally GST Registration</li>
        <li>ITR Filing</li>
        
        <li>Tally GST Registration</li>
        <li>ITR Filing</li>
        
      </ul>
    </div>
  );
};

export default Services;
